<template>
  <v-col cols="12">
    <v-row>
      <v-col class="pt-1 pb-0" cols="1" style="min-width: 4em">
        <v-text-field v-model="internalValue" class="mt-0 pt-0" style="min-width: 4em" outlined dense type="number"
          :min="0" width="3em" :rules="aboveOrEqual0AndIntegerRules" :disabled="disabled"></v-text-field>
      </v-col>
      <v-col :class="{
          'pb-2': true,
          'pt-0': true,
          'px-4': true,
          disabled,
        }" :cols="showPreise ? 8 : 10">
        <v-menu v-if="m.setartikel" open-on-hover offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              {{ $t(`prod_typ.${m.prod_typ}`) }}<span v-for="merkmal in ['INHALT', 'INSTR', 'TONART']" :key="merkmal">{{
          (m.merkmale_mapped[merkmal] &&
            ', ' + $t(
              `${merkmal}.${m.merkmale_mapped[merkmal].qaus_id}`
            )) }}</span><br />Carus {{ m.artikel_nr_formatiert }} <span style="color:orange"
                v-if="m.meld_nr === '21'">{{ $t(`meld_nr.${m.meld_nr}`) }}<span v-if="m.aufl_dat_soll">, {{ $t('voraussichtlich lieferbar ab') }} {{ m.aufl_dat_soll | formatDate('MM/YYYY') }}</span></span>
            </div>
          </template>
          <v-card>
            <v-card-title> {{ $t('Setinhalte') }} </v-card-title>
            <v-card-text class="intro pt-3">
              <v-row dense
                v-for="sa in m.setartikel.toSorted((saA, saB) => saA.artikel_nr.localeCompare(saB.artikel_nr))"
                :key="sa.artikel_nr">
                <v-col>
                  {{ sa.menge }}x
                  {{ $t(`prod_typ.${sa.prod_typ}`) }}<span v-for="merkmal in ['INHALT', 'INSTR', 'TONART']"
                    :key="merkmal">{{
          (sa.merkmale_mapped[merkmal] &&
            ', ' + $t(
              `${merkmal}.${sa.merkmale_mapped[merkmal].qaus_id}`
            )) }}</span>, Carus
                  {{
          sa.artikel_nr_formatiert || sa.artikel_nr
        }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
        <div v-else>
          {{ $t(`prod_typ.${m.prod_typ}`) }}<span v-for="merkmal in ['INHALT', 'INSTR', 'TONART', 'DATEI']" :key="merkmal">{{
          (m.merkmale_mapped[merkmal] &&
            ', ' + $t(
              `${merkmal}.${m.merkmale_mapped[merkmal].qaus_id}`
            )) }}</span><v-icon class="ml-2" @click="openHint" v-if="m.merkmale_mapped['DATEI'] && m.merkmale_mapped['DATEI'].qaus_id == 'XML'">mdi-information</v-icon><br />Carus {{ m.artikel_nr_formatiert }} <span style="color:orange"
            v-if="m.meld_nr === '21'"><br />{{
          $t(`meld_nr.${m.meld_nr}`) }}<span v-if="m.aufl_dat_soll">, {{ $t('voraussichtlich lieferbar ab') }} {{
            m.aufl_dat_soll | formatDate('MM/YYYY') }}</span></span>
        </div>
      </v-col>
      <v-col v-if="showPreise" :cols="showPreise ? 3 : 1">
        {{ m.preise[0].preis | formatCurrency }}
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

export default {
  props: {
    m: Object,
    disabled: Boolean,
    value: Number,
    showPreise: Boolean,
  },
  data() {
    return {
      internalValue: this.value,
      aboveOrEqual0AndIntegerRules: [
        (v) => Number.isInteger(Number(v)) || this.$t("Muss ganzzahlig sein!"),
        (v) => Number(v) >= 0 || this.$t("Muss >= 0 sein!"),
      ]
    }
  },
  methods: {
    openHint() {

      let link = 'https://www.carus-verlag.com/auffuehrungen';
      if (this.$i18n.locale === 'en') {
        link = 'https://www.carus-verlag.com/en/performances/';
      } else if (this.$i18n.locale === 'fr') {
        link = 'https://www.carus-verlag.com/fr/representations/';
      }
      window.open(link, '_blank');
    }
  },
  watch: {
    internalValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.internalValue = newVal;
    }
  }
};
</script>