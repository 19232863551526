let shopHostname = window.location.hostname;
if (shopHostname.startsWith("client") || shopHostname.startsWith("localhost")) {
  shopHostname = "https://new.staging.carus-verlag.com";
} else {
  shopHostname = "https://www.carus-verlag.com";
}

export default {
  // product display information
  product: {
    name: "carus performance portal",
    version: "24.12.12.0",
    gitsha: "c0db87e1efba42a77b948465062b566df5303e9d",
    shopHostname,
  },

};
